import { Column } from '@ant-design/plots';
import { G2 } from '@ant-design/plots';
import { DualAxes } from '@ant-design/plots';
import { etalon } from './const';

const { registerTheme } = G2;
registerTheme('custom-theme', {
    colors10: [
      '#025DF4',
      '#DB6BCF',
      '#2498D1',
      '#BBBDE6',
      '#4045B2',
      '#21A97A',
      '#FF745A',
      '#007E99',
      '#FFA8A8',
      '#2391FF',
    ],
    colors20: [
      '#025DF4',
      '#DB6BCF',
      '#2498D1',
      '#BBBDE6',
      '#4045B2',
      '#21A97A',
      '#FF745A',
      '#007E99',
      '#FFA8A8',
      '#2391FF',
      '#FFC328',
      '#A0DC2C',
      '#946DFF',
      '#626681',
      '#EB4185',
      '#CD8150',
      '#36BCCB',
      '#327039',
      '#803488',
      '#83BC99',
    ],
});

const RangeColumn = (props) => {
  const {data} = props;

  //const d = data.sort((a,b) => a.date - b.date); 
  //console.log('sort',data);

  function compare(a, b) {

    var dateA = new Date(a.dat);
    var dateB = new Date(b.dat);
    //console.log(a.dat, b.dat, dateA, dateB)

    return dateA - dateB;
    //return b.key - a.key;
  }
  //data.sort(compare);

  let dataE = [];
  //
  for (let key in etalon) {
    let y = false;
    data.forEach(element => {
      
      if (key === element.type) {
        y = true;
        dataE.push(element);
      }
      //console.log('data AAA', y, key, etalon[key], element);
      
      // dataE.push({"type":key, "values": [10,11]});
    })
    if (y === false) {
      dataE.push({"type":key, "values": [0,0]});
    } 
  }


  //data.sort(compare);
  //console.log('data DDD', data);

  const config = {
    //appendPadding: 10,
    data: dataE,
    //data: [data, dataE],
    //data: dataE,
    xField: 'type',
    //xField: ['type','type'],
    yField: 'values',
    //yField: ['values', 'values'],
    isRange: true,
    //angleField: 'value',
    //colorField: 'type',
    //radius: 0.8,
    yAxis: {
      label: {
        formatter: (v) => `${(v / 100).toFixed(2)} B`,
        //formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),

      },
    },

    // geometryOptions: [
    //   {
    //     geometry: 'column',
    //     isStack: true,
    //     seriesField: 'type',
    //     columnWidthRatio: 0.4,
    //     label: {},
    //   },
    //   {
    //     geometry: 'line',
    //   },
    // ],



    //columnBackground: {
       //style: {
            //   fill: 'rgba(0,0,0,0.1)',
       //     fill: '#325',
       //     fillOpacity: 0.1,
       //},

      // style: ({data}) => {
      //   //let r = getWeekDay(date);
      //   //let r = date.getDay();
      //   console.log(data);
      //   //if (r === 5) {
      //     return {
      //       //fill: 'rgba(0,0,0,0.1)'
      //       fill: 'rgba(10,200,0,0.1)'
      //     };
      //   //}
      // },
    //},
    // animation: {
    //   appear: {
    //     animation: 'path-in',
    //     duration: 5000,
    //   },
    // },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    theme: 'custom-theme',
  };
  //return <DualAxes {...config} />;
  return <Column {...config} />;
};

export default RangeColumn;