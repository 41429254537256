import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { StarFilled, AndroidFilled } from '@ant-design/icons';
import { metrics, metricsOS } from './const';

const CrashRate = () => {
  const [data, setData] = useState(metrics);
  


  const config = {
    data,
    padding: 'auto',
    xField: 'version',
    yField: 'crash',
    lineStyle: {
      //stroke: '#ffd700',
      lineWidth: 4,
      cursor: 'pointer'
    },
  };

  return (
  <>
    <div style={{marginBottom: '10px'}}><b>Crash Free Session Rate</b><br />Частота сеансов без сбоев (в процентах)</div>
    <Line {...config} />
  </>
  );
};

export default CrashRate;