  export const etalon = {
  "01-01-2024":  {time: "01-01-2024", count: 0},
  "02-01-2024":  {time: "02-01-2024", count: 0},
  "03-01-2024":  {time: "03-01-2024", count: 0},
  "04-01-2024":  {time: "04-01-2024", count: 0},
  "05-01-2024":  {time: "05-01-2024", count: 0},
  "06-01-2024":  {time: "06-01-2024", count: 0},
  "07-01-2024":  {time: "07-01-2024", count: 0},
  "08-01-2024":  {time: "08-01-2024", count: 0},
  "09-01-2024":  {time: "09-01-2024", count: 0},
  "10-01-2024":  {time: "10-01-2024", count: 0},
  "11-01-2024":  {time: "11-01-2024", count: 0},
  "12-01-2024":  {time: "12-01-2024", count: 0},
  "13-01-2024":  {time: "13-01-2024", count: 0},
  "14-01-2024":  {time: "14-01-2024", count: 0},
  "15-01-2024":  {time: "15-01-2024", count: 0},
  "16-01-2024":  {time: "16-01-2024", count: 0},
  "17-01-2024":  {time: "17-01-2024", count: 0},
  "18-01-2024":  {time: "18-01-2024", count: 0},
  "19-01-2024":  {time: "19-01-2024", count: 0},
  "20-01-2024":  {time: "20-01-2024", count: 0},
  "21-01-2024":  {time: "21-01-2024", count: 0},
  "22-01-2024":  {time: "22-01-2024", count: 0},
  "23-01-2024":  {time: "23-01-2024", count: 0},
  "24-01-2024":  {time: "24-01-2024", count: 0},
  "25-01-2024":  {time: "25-01-2024", count: 0},
  "26-01-2024":  {time: "26-01-2024", count: 0},
  "27-01-2024":  {time: "27-01-2024", count: 0},
  "28-01-2024":  {time: "28-01-2024", count: 0},
  "29-01-2024":  {time: "29-01-2024", count: 0},
  "30-01-2024":  {time: "30-01-2024", count: 0},
  "31-01-2024":  {time: "31-01-2024", count: 0},
  "01-02-2024":  {time: "01-02-2024", count: 0},
  "02-02-2024":  {time: "02-02-2024", count: 0},
  "03-02-2024":  {time: "03-02-2024", count: 0},
  "04-02-2024":  {time: "04-02-2024", count: 0},
  "05-02-2024":  {time: "05-02-2024", count: 0},
  "06-02-2024":  {time: "06-02-2024", count: 0},
  "07-02-2024":  {time: "07-02-2024", count: 0},
  "08-02-2024":  {time: "08-02-2024", count: 0},
  "09-02-2024":  {time: "09-02-2024", count: 0},
  "10-02-2024":  {time: "10-02-2024", count: 0},
  "11-02-2024":  {time: "11-02-2024", count: 0},
  "12-02-2024":  {time: "12-02-2024", count: 0},
  "13-02-2024":  {time: "13-02-2024", count: 0},
  "14-02-2024":  {time: "14-02-2024", count: 0},
  "15-02-2024":  {time: "15-02-2024", count: 0},
  "16-02-2024":  {time: "16-02-2024", count: 0},
  "17-02-2024":  {time: "17-02-2024", count: 0},
  "18-02-2024":  {time: "18-02-2024", count: 0},
  "19-02-2024":  {time: "19-02-2024", count: 0},
  "20-02-2024":  {time: "20-02-2024", count: 0},
  "21-02-2024":  {time: "21-02-2024", count: 0},
  "22-02-2024":  {time: "22-02-2024", count: 0},
  "23-02-2024":  {time: "23-02-2024", count: 0},
  "24-02-2024":  {time: "24-02-2024", count: 0},
  "25-02-2024":  {time: "25-02-2024", count: 0},
  "26-02-2024":  {time: "26-02-2024", count: 0},
  "27-02-2024":  {time: "27-02-2024", count: 0},
  "28-02-2024":  {time: "28-02-2024", count: 0},
  "29-02-2024":  {time: "29-02-2024", count: 0},
  "01-03-2024":  {time: "01-03-2024", count: 0},
  "02-03-2024":  {time: "02-03-2024", count: 0},
  "03-03-2024":  {time: "03-03-2024", count: 0},
  "04-03-2024":  {time: "04-03-2024", count: 0},
  "05-03-2024":  {time: "05-03-2024", count: 0},
  "06-03-2024":  {time: "06-03-2024", count: 0},
  "07-03-2024":  {time: "07-03-2024", count: 0},
  "08-03-2024":  {time: "08-03-2024", count: 0},
  "09-03-2024":  {time: "09-03-2024", count: 0},
  "10-03-2024":  {time: "10-03-2024", count: 0},
  "11-03-2024":  {time: "11-03-2024", count: 0},
  "12-03-2024":  {time: "12-03-2024", count: 0},
  "13-03-2024":  {time: "13-03-2024", count: 0},
  "14-03-2024":  {time: "14-03-2024", count: 0},
  "15-03-2024":  {time: "15-03-2024", count: 0},
  "16-03-2024":  {time: "16-03-2024", count: 0},
  "17-03-2024":  {time: "17-03-2024", count: 0},
  "18-03-2024":  {time: "18-03-2024", count: 0},
  "19-03-2024":  {time: "19-03-2024", count: 0},
  "20-03-2024":  {time: "20-03-2024", count: 0},
  "21-03-2024":  {time: "21-03-2024", count: 0},
  "22-03-2024":  {time: "22-03-2024", count: 0},
  "23-03-2024":  {time: "23-03-2024", count: 0},
  "24-03-2024":  {time: "24-03-2024", count: 0},
  "25-03-2024":  {time: "25-03-2024", count: 0},
  "26-03-2024":  {time: "26-03-2024", count: 0},
  "27-03-2024":  {time: "27-03-2024", count: 0},
  "28-03-2024":  {time: "28-03-2024", count: 0},
  "29-03-2024":  {time: "29-03-2024", count: 0},
  "30-03-2024":  {time: "30-03-2024", count: 0},
  "31-03-2024":  {time: "31-03-2024", count: 0},
  }; 
export const etalon_new = {
  "01-01-2024":  {time: "01-01-2024", count: 0},
  "02-01-2024":  {time: "02-01-2024", count: 0},
  "03-01-2024":  {time: "03-01-2024", count: 0},
  "04-01-2024":  {time: "04-01-2024", count: 0},
  "05-01-2024":  {time: "05-01-2024", count: 0},
  "06-01-2024":  {time: "06-01-2024", count: 0},
  "07-01-2024":  {time: "07-01-2024", count: 0},
  "08-01-2024":  {time: "08-01-2024", count: 0},
  "09-01-2024":  {time: "09-01-2024", count: 0},
  "10-01-2024":  {time: "10-01-2024", count: 0},
  "11-01-2024":  {time: "11-01-2024", count: 0},
  "12-01-2024":  {time: "12-01-2024", count: 0},
  "13-01-2024":  {time: "13-01-2024", count: 0},
  "14-01-2024":  {time: "14-01-2024", count: 0},
  "15-01-2024":  {time: "15-01-2024", count: 0},
  "16-01-2024":  {time: "16-01-2024", count: 0},
  "17-01-2024":  {time: "17-01-2024", count: 0},
  "18-01-2024":  {time: "18-01-2024", count: 0},
  "19-01-2024":  {time: "19-01-2024", count: 0},
  "20-01-2024":  {time: "20-01-2024", count: 0},
  "21-01-2024":  {time: "21-01-2024", count: 0},
  "22-01-2024":  {time: "22-01-2024", count: 0},
  "23-01-2024":  {time: "23-01-2024", count: 0},
  "24-01-2024":  {time: "24-01-2024", count: 0},
  "25-01-2024":  {time: "25-01-2024", count: 0},
  "26-01-2024":  {time: "26-01-2024", count: 0},
  "27-01-2024":  {time: "27-01-2024", count: 0},
  "28-01-2024":  {time: "28-01-2024", count: 0},
  "29-01-2024":  {time: "29-01-2024", count: 0},
  "30-01-2024":  {time: "30-01-2024", count: 0},
  "31-01-2024":  {time: "31-01-2024", count: 0},
  "01-02-2024":  {time: "01-02-2024", count: 0},
  "02-02-2024":  {time: "02-02-2024", count: 0},
  "03-02-2024":  {time: "03-02-2024", count: 0},
  "04-02-2024":  {time: "04-02-2024", count: 0},
  "05-02-2024":  {time: "05-02-2024", count: 0},
  "06-02-2024":  {time: "06-02-2024", count: 0},
  "07-02-2024":  {time: "07-02-2024", count: 0},
  "08-02-2024":  {time: "08-02-2024", count: 0},
  "09-02-2024":  {time: "09-02-2024", count: 0},
  "10-02-2024":  {time: "10-02-2024", count: 0},
  "11-02-2024":  {time: "11-02-2024", count: 0},
  "12-02-2024":  {time: "12-02-2024", count: 0},
  "13-02-2024":  {time: "13-02-2024", count: 0},
  "14-02-2024":  {time: "14-02-2024", count: 0},
  "15-02-2024":  {time: "15-02-2024", count: 0},
  "16-02-2024":  {time: "16-02-2024", count: 0},
  "17-02-2024":  {time: "17-02-2024", count: 0},
  "18-02-2024":  {time: "18-02-2024", count: 0},
  "19-02-2024":  {time: "19-02-2024", count: 0},
  "20-02-2024":  {time: "20-02-2024", count: 0},
  "21-02-2024":  {time: "21-02-2024", count: 0},
  "22-02-2024":  {time: "22-02-2024", count: 0},
  "23-02-2024":  {time: "23-02-2024", count: 0},
  "24-02-2024":  {time: "24-02-2024", count: 0},
  "25-02-2024":  {time: "25-02-2024", count: 0},
  "26-02-2024":  {time: "26-02-2024", count: 0},
  "27-02-2024":  {time: "27-02-2024", count: 0},
  "28-02-2024":  {time: "28-02-2024", count: 0},
  "29-02-2024":  {time: "29-02-2024", count: 0},
  "01-03-2024":  {time: "01-03-2024", count: 0},
  "02-03-2024":  {time: "02-03-2024", count: 0},
  "03-03-2024":  {time: "03-03-2024", count: 0},
  "04-03-2024":  {time: "04-03-2024", count: 0},
  "05-03-2024":  {time: "05-03-2024", count: 0},
  "06-03-2024":  {time: "06-03-2024", count: 0},
  "07-03-2024":  {time: "07-03-2024", count: 0},
  "08-03-2024":  {time: "08-03-2024", count: 0},
  "09-03-2024":  {time: "09-03-2024", count: 0},
  "10-03-2024":  {time: "10-03-2024", count: 0},
  "11-03-2024":  {time: "11-03-2024", count: 0},
  "12-03-2024":  {time: "12-03-2024", count: 0},
  "13-03-2024":  {time: "13-03-2024", count: 0},
  "14-03-2024":  {time: "14-03-2024", count: 0},
  "15-03-2024":  {time: "15-03-2024", count: 0},
  "16-03-2024":  {time: "16-03-2024", count: 0},
  "17-03-2024":  {time: "17-03-2024", count: 0},
  "18-03-2024":  {time: "18-03-2024", count: 0},
  "19-03-2024":  {time: "19-03-2024", count: 0},
  "20-03-2024":  {time: "20-03-2024", count: 0},
  "21-03-2024":  {time: "21-03-2024", count: 0},
  "22-03-2024":  {time: "22-03-2024", count: 0},
  "23-03-2024":  {time: "23-03-2024", count: 0},
  "24-03-2024":  {time: "24-03-2024", count: 0},
  "25-03-2024":  {time: "25-03-2024", count: 0},
  "26-03-2024":  {time: "26-03-2024", count: 0},
  "27-03-2024":  {time: "27-03-2024", count: 0},
  "28-03-2024":  {time: "28-03-2024", count: 0},
  "29-03-2024":  {time: "29-03-2024", count: 0},
  "30-03-2024":  {time: "30-03-2024", count: 0},
  "31-03-2024":  {time: "31-03-2024", count: 0},
  }; 

export const etalon_stack = [
  {time: "01-01-2024", value: 0, type: "МП Курьеры"},
  {time: "02-01-2024", count: 0, type: "МП Курьеры"},
  {time: "03-01-2024", count: 0, type: "МП Курьеры"},
  {time: "04-01-2024", count: 0, type: "МП Курьеры"},
  {time: "05-01-2024", count: 0, type: "МП Курьеры"},
  {time: "06-01-2024", count: 0, type: "МП Курьеры"},
  {time: "07-01-2024", count: 0, type: "МП Курьеры"},
  {time: "08-01-2024", count: 0, type: "МП Курьеры"},
  {time: "09-01-2024", count: 0, type: "МП Курьеры"},
  {time: "10-01-2024", count: 0, type: "МП Курьеры"},
  {time: "11-01-2024", count: 0, type: "МП Курьеры"},
  {time: "12-01-2024", count: 0, type: "МП Курьеры"},
  {time: "13-01-2024", count: 0, type: "МП Курьеры"},
  {time: "14-01-2024", count: 0, type: "МП Курьеры"},
  {time: "15-01-2024", count: 0, type: "МП Курьеры"},
  {time: "16-01-2024", count: 0, type: "МП Курьеры"},
  {time: "17-01-2024", count: 0, type: "МП Курьеры"},
  {time: "18-01-2024", count: 0, type: "МП Курьеры"},
  {time: "19-01-2024", count: 0, type: "МП Курьеры"},
  {time: "20-01-2024", count: 0, type: "МП Курьеры"},
  {time: "21-01-2024", count: 0, type: "МП Курьеры"},
  {time: "22-01-2024", count: 0, type: "МП Курьеры"},
  {time: "23-01-2024", count: 0, type: "МП Курьеры"},
  {time: "24-01-2024", count: 0, type: "МП Курьеры"},
  {time: "25-01-2024", count: 0, type: "МП Курьеры"},
  {time: "26-01-2024", count: 0, type: "МП Курьеры"},
  {time: "27-01-2024", count: 0, type: "МП Курьеры"},
  {time: "28-01-2024", count: 0, type: "МП Курьеры"},
  {time: "29-01-2024", count: 0, type: "МП Курьеры"},
  {time: "30-01-2024", count: 0, type: "МП Курьеры"},
  {time: "31-01-2024", count: 0, type: "МП Курьеры"},
  {time: "01-02-2024", count: 0, type: "МП Курьеры"},
  {time: "02-02-2024", count: 0, type: "МП Курьеры"},
  {time: "03-02-2024", count: 0, type: "МП Курьеры"},
  {time: "04-02-2024", count: 0, type: "МП Курьеры"},
  {time: "05-02-2024", count: 0, type: "МП Курьеры"},
  {time: "06-02-2024", count: 0, type: "МП Курьеры"},
  {time: "07-02-2024", count: 0, type: "МП Курьеры"},
  {time: "08-02-2024", count: 0, type: "МП Курьеры"},
  {time: "09-02-2024", count: 0, type: "МП Курьеры"},
  {time: "10-02-2024", count: 0, type: "МП Курьеры"},
  {time: "11-02-2024", count: 0, type: "МП Курьеры"},
  {time: "12-02-2024", count: 0, type: "МП Курьеры"},
  {time: "13-02-2024", count: 0, type: "МП Курьеры"},
  {time: "14-02-2024", count: 0, type: "МП Курьеры"},
  {time: "15-02-2024", count: 0, type: "МП Курьеры"},
  {time: "16-02-2024", count: 0, type: "МП Курьеры"},
  {time: "17-02-2024", count: 0, type: "МП Курьеры"},
  {time: "18-02-2024", count: 0, type: "МП Курьеры"},
  {time: "19-02-2024", count: 0, type: "МП Курьеры"},
  {time: "20-02-2024", count: 0, type: "МП Курьеры"},
  {time: "21-02-2024", count: 0, type: "МП Курьеры"},
  {time: "22-02-2024", count: 0, type: "МП Курьеры"},
  {time: "23-02-2024", count: 0, type: "МП Курьеры"},
  {time: "24-02-2024", count: 0, type: "МП Курьеры"},
  {time: "25-02-2024", count: 0, type: "МП Курьеры"},
  {time: "26-02-2024", count: 0, type: "МП Курьеры"},
  {time: "27-02-2024", count: 0, type: "МП Курьеры"},
  {time: "28-02-2024", count: 0, type: "МП Курьеры"},
  {time: "29-02-2024", count: 0, type: "МП Курьеры"},
  {time: "01-03-2024", count: 0, type: "МП Курьеры"},
  {time: "02-03-2024", count: 0, type: "МП Курьеры"},
  {time: "03-03-2024", count: 0, type: "МП Курьеры"},
  {time: "04-03-2024", count: 0, type: "МП Курьеры"},
  {time: "05-03-2024", count: 0, type: "МП Курьеры"},
  {time: "06-03-2024", count: 0, type: "МП Курьеры"},
  {time: "07-03-2024", count: 0, type: "МП Курьеры"},
  {time: "08-03-2024", count: 0, type: "МП Курьеры"},
  {time: "09-03-2024", count: 0, type: "МП Курьеры"},
  {time: "10-03-2024", count: 0, type: "МП Курьеры"},
  {time: "11-03-2024", count: 0, type: "МП Курьеры"},
  {time: "12-03-2024", count: 0, type: "МП Курьеры"},
  {time: "13-03-2024", count: 0, type: "МП Курьеры"},
  {time: "14-03-2024", count: 0, type: "МП Курьеры"},
  {time: "15-03-2024", count: 0, type: "МП Курьеры"},
  {time: "16-03-2024", count: 0, type: "МП Курьеры"},
  {time: "17-03-2024", count: 0, type: "МП Курьеры"},
  {time: "18-03-2024", count: 0, type: "МП Курьеры"},
  {time: "19-03-2024", count: 0, type: "МП Курьеры"},
  {time: "20-03-2024", count: 0, type: "МП Курьеры"},
  {time: "21-03-2024", count: 0, type: "МП Курьеры"},
  {time: "22-03-2024", count: 0, type: "МП Курьеры"},
  {time: "23-03-2024", count: 0, type: "МП Курьеры"},
  {time: "24-03-2024", count: 0, type: "МП Курьеры"},
  {time: "25-03-2024", count: 0, type: "МП Курьеры"},
  {time: "26-03-2024", count: 0, type: "МП Курьеры"},
  {time: "27-03-2024", count: 0, type: "МП Курьеры"},
  {time: "28-03-2024", count: 0, type: "МП Курьеры"},
  {time: "29-03-2024", count: 0, type: "МП Курьеры"},
  {time: "30-03-2024", count: 0, type: "МП Курьеры"},
  {time: "31-03-2024", count: 0, type: "МП Курьеры"}

  // {time: "01-01-2024", value: 0, type: "МП Курьеры + Смежн"},
  // {time: "02-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "03-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "04-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "05-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "06-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "07-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "08-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "09-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "10-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "11-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "12-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "13-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "14-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "15-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "16-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "17-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "18-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "19-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "20-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "21-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "22-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "23-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "24-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "25-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "26-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "27-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "28-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "29-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "30-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "31-01-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "01-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "02-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "03-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "04-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "05-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "06-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "07-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "08-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "09-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "10-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "11-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "12-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "13-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "14-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "15-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "16-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "17-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "18-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "19-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "20-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "21-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "22-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "23-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "24-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "25-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "26-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "27-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "28-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "29-02-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "01-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "02-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "03-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "04-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "05-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "06-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "07-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "08-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "09-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "10-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "11-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "12-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "13-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "14-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "15-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "16-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "17-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "18-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "19-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "20-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "21-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "22-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "23-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "24-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "25-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "26-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "27-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "28-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "29-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "30-03-2024", count: 0, type: "МП Курьеры + Смежн"},
  // {time: "31-03-2024", count: 0, type: "МП Курьеры + Смежн"},  
]; 

export const etalon_release = [
  {"date": "01-01-2024", "value": 0, "type": "Релизные дни"},
  {"date": "02-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "03-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "04-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "05-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "06-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "07-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "08-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "09-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "10-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "11-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "12-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "13-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "14-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "15-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "16-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "17-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "18-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "19-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "20-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "21-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "22-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "23-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "24-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "25-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "26-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "27-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "28-01-2024", "value": null, "type": "Релизные дни"},
  {"date": "29-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "30-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "31-01-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "01-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "02-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "03-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "04-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "05-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "06-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "07-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "08-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "09-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "10-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "11-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "12-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "13-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "14-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "15-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "16-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "17-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "18-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "19-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "20-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "21-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "22-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "23-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "24-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "25-02-2024", "value": null, "type": "Релизные дни"},
  {"date": "26-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "27-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "28-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "29-02-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "01-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "02-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "03-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "04-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "05-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "06-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "07-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "08-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "09-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "10-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "11-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "12-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "13-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "14-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "15-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "16-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "17-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "18-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "19-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "20-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "21-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "22-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "23-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "24-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "25-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "26-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "27-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "28-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "29-03-2024", "value": 2000, "type": "Релизные дни"},
  {"date": "30-03-2024", "value": null, "type": "Релизные дни"},
  {"date": "31-03-2024", "value": null, "type": "Релизные дни"},
];  

export const metrics = [
{
    "version": "3.5.16",
    "time": "Mar 26, 2024 02:00",
    "crash": null,
    "result": 30,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 5,
        "meh": 43,
        "poor": 52,
        "p75": 5907,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 19,
        "meh": 27,
        "poor": 54,
        "p75": 7466,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 82,
        "meh": 10,
        "poor": 8,
        "p75": 80,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 44,
        "meh": 0,
        "poor": 56,
        "p75": 0.42,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]    
  },
  {
    "version": "3.6.0",
    "time": "Apr 4, 2024 12:22",
    "crash": null,
    "result": 34,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 5,
        "meh": 41,
        "poor": 54,
        "p75": 6143,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 19,
        "meh": 24,
        "poor": 57,
        "p75": 8036,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 83,
        "meh": 17,
        "poor": 0,
        "p75": 56,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.31,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]    
  },
    {
    "version": "3.6.1",
    "time": "Apr 5, 2024 01:39",
    "crash": null,
    "result": 49,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 6,
        "meh": 44,
        "poor": 50,
        "p75": 5839,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 21,
        "meh": 26,
        "poor": 54,
        "p75": 7410,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 77,
        "meh": 8,
        "poor": 15,
        "p75": 83,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.01,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]    
  },
  {
    "version": "4.0.0",
    "time": "Apr 18, 2024 01:30",
    "crash": null,
    "result": 53,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 11,
        "meh": 57,
        "poor": 32,
        "p75": 3683,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 52,
        "meh": 25,
        "poor": 23,
        "p75": 3846,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 71,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.0.1",
    "time": "Apr 22, 2024 01:52",
    "crash": null,
    "result": 65,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 15,
        "meh": 56,
        "poor": 29,
        "p75": 3398,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 58,
        "meh": 21,
        "poor": 21,
        "p75": 3582,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 88,
        "meh": 0,
        "poor": 12,
        "p75": 40,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 75,
        "meh": 0,
        "poor": 25,
        "p75": 0.22,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
    {
    "version": "4.0.3",
    "time": "Apr 26, 2024 03:52",
    "crash": 5,
    "result": 63,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 17,
        "meh": 55,
        "poor": 28,
        "p75": 3227,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 60,
        "meh": 20,
        "poor": 20,
        "p75": 3468,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 83,
        "meh": 4,
        "poor": 13,
        "p75": 56,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 60,
        "meh": 0,
        "poor": 40,
        "p75": 0.25,//0.42,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
    {
    "version": "4.1.3",
    "time": "May 15, 2024 3:45",
    "crash": 33,
    "result": 64,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 12,
        "meh": 57,
        "poor": 31,
        "p75": 3482,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 52,
        "meh": 25,
        "poor": 23,
        "p75": 3736,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 90,
        "meh": 0,
        "poor": 10,
        "p75": 61,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.1.4",
    "time": "May 16, 2024 12:29",
    "crash": 7,
    "result": 55,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 15,
        "meh": 58,
        "poor": 27,
        "p75": 3140,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 60,
        "meh": 21,
        "poor": 19,
        "p75": 3376,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 88,
        "meh": 2,
        "poor": 10,
        "p75": 43,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 25,
        "meh": 0,
        "poor": 75,
        "p75": 0.51,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "4.1.5",
    "time": "May 17, 2024 03:01",
    "crash": 94,
    "result": 50,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 15,
        "meh": 56,
        "poor": 29,
        "p75": 3356,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 56,
        "meh": 23,
        "poor": 21,
        "p75": 3620,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 72,
        "meh": 9,
        "poor": 19,
        "p75": 142,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 43,
        "meh": 0,
        "poor": 57,
        "p75": 0.44,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
    ,
  {
    "version": "4.2.1",
    "time": "May 24, 2024 12:25",
    "crash": 95.66,
    "result": 60,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 17,
        "meh": 56,
        "poor": 27,
        "p75": 3140,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 60,
        "meh": 20,
        "poor": 20,
        "p75": 3419,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 78,
        "meh": 19,
        "poor": 4,
        "p75": 48,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 50,
        "meh": 0,
        "poor": 50,
        "p75": 0.35,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.3.5",
    "time": "Jun 6, 2024 07:57",
    "crash": 99.98,
    "result": 68,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 22,
        "meh": 56,
        "poor": 22,
        "p75": 2767,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 63,
        "meh": 19,
        "poor": 18,
        "p75": 3188,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 87,
        "meh": 3,
        "poor": 10,
        "p75": 32,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 98,
        "meh": 0,
        "poor": 2,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.4.0",
    "time": "Jun 11, 2024 07:37",
    "crash": 99.969,
    "result": 70,
    "metric": [ 
      { 
        "val": "FCP",
        "good": 21,
        "meh": 58,
        "poor": 21,
        "p75": 2781,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 64,
        "meh": 23,
        "poor": 13,
        "p75": 3013,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 91,
        "meh": 0,
        "poor": 9,
        "p75": 30,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 99,
        "meh": 0,
        "poor": 1,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.5.0",
    "time": "Jun 25, 2024 12:12",
    "crash": 99.971,
    "result": 63,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 15,
        "meh": 58,
        "poor": 27,
        "p75": 3154,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 61,
        "meh": 23,
        "poor": 17,
        "p75": 3305,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 41,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.5.2",
    "time": "Jun 28, 2024 18:18",
    "crash": 99.977,
    "result": 60,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 22,
        "meh": 56,
        "poor": 22,
        "p75": 2854,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 63,
        "meh": 21,
        "poor": 16,
        "p75": 3090,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 80,
        "meh": 3,
        "poor": 17,
        "p75": 56,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.5.3",
    "time": "Jul 2, 2024 16:00",
    "crash": 99.965,
    "result": 57,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 21,
        "meh": 56,
        "poor": 23,
        "p75": 2882,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 63,
        "meh": 22,
        "poor": 15,
        "p75": 3079,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 83,
        "meh": 0,
        "poor": 17,
        "p75": 53,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 99,
        "meh": 0,
        "poor": 1,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.6.0",
    "time": "Jul 9, 2024 09:49",
    "crash": 99.976,
    "result": 64,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 14,
        "meh": 64,
        "poor": 22,
        "p75": 2808,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 64,
        "meh": 20,
        "poor": 16,
        "p75": 2969,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 39,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 94,
        "meh": 0,
        "poor": 6,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.6.1",
    "time": "Jul 12, 2024 14:26",
    "crash": 99.977,
    "result": 61,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 20,
        "meh": 58,
        "poor": 22,
        "p75": 2759,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 64,
        "meh": 21,
        "poor": 15,
        "p75": 3019,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 75,
        "meh": 7,
        "poor": 18,
        "p75": 66,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.6.2",
    "time": "Jul 18, 2024 19:05",
    "crash": 99.983,
    "result": 68,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 23,
        "meh": 58,
        "poor": 20,
        "p75": 2644,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 68,
        "meh": 19,
        "poor": 13,
        "p75": 2909,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 78,
        "meh": 3,
        "poor": 19,
        "p75": 45,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 98,
        "meh": 0,
        "poor": 2,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.7.0",
    "time": "Jul 23, 2024 13:00",
    "crash": 99.983,
    "result": 78,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 79,
        "meh": 16,
        "poor": 5,
        "p75": 783,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 93,
        "meh": 7,
        "poor": 0,
        "p75": 789,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 75,
        "meh": 25,
        "poor": 0,
        "p75": 85,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 94,
        "meh": 0,
        "poor": 6,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.7.1",
    "time": "Jul 26, 2024 14:33",
    "crash": 99.896,
    "result": 83,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 88,
        "meh": 11,
        "poor": 1,
        "p75": 643,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 99,
        "meh": 1,
        "poor": 0,
        "p75": 846,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 18, 
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 98,
        "meh": 0,
        "poor": 2,
        "p75": 0.0, 
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.7.2",
    "time": "Jul 29, 2024 14:51",
    "crash": 99.896,
    "result": 80,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 90,
        "meh": 9,
        "poor": 1,
        "p75": 576,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 721,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 88,
        "meh": 12,
        "poor": 0,
        "p75": 21,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 97,
        "meh": 0,
        "poor": 3,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.7.3",
    "time": "Jul 30, 2024 21:00",
    "crash": 99.955,
    "result": 76,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 78,
        "meh": 18,
        "poor": 4,
        "p75": 882,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 88,
        "meh": 8,
        "poor": 4,
        "p75": 1450,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 98,
        "meh": 2,
        "poor": 0,
        "p75": 26,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 97,
        "meh": 0,
        "poor": 3,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "4.7.4",
    "time": "Aug 5, 2024 13:25",
    "crash": 99.952,
    "result": 82,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 91,
        "meh": 6,
        "poor": 3,
        "p75": 682,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 99,
        "meh": 1,
        "poor": 0,
        "p75": 707,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 94,
        "meh": 0,
        "poor": 6,
        "p75": 24,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
    ,
  {
    "version": "4.8.0",
    "time": "Aug 7, 2024 12:00",
    "crash": 99.96,
    "result": 78,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 87,
        "meh": 12,
        "poor": 1,
        "p75": 703,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 97,
        "meh": 0,
        "poor": 3,
        "p75": 904,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 92,
        "meh": 8,
        "poor": 0,
        "p75": 33,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 99,
        "meh": 0,
        "poor": 1,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
   ,
  {
    "version": "4.9.0",
    "time": "Aug 22, 2024 10:00",
    "crash": 99.957,
    "result": 77,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 84,
        "meh": 16,
        "poor": 0,
        "p75": 912,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 99,
        "meh": 1,
        "poor": 0,
        "p75": 992,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 29,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }   
  ,
  {
    "version": "4.9.1",
    "time": "Aug 28, 2024 16:14",
    "crash": 99.951,
    "result": 90,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 89,
        "meh": 11,
        "poor": 1,
        "p75": 684,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 97,
        "meh": 3,
        "poor": 0,
        "p75": 853,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 13, //14
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 98,
        "meh": 0,
        "poor": 3,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  },
  {
    "version": "4.9.2",
    "time": "Sep 4, 2024 17:01",
    "crash": 99.958,
    "result": 83,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 88,
        "meh": 10,
        "poor": 2,
        "p75": 750,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 97,
        "meh": 3,
        "poor": 0,
        "p75": 1011,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 89,
        "meh": 11,
        "poor": 0,
        "p75": 23,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 98,
        "meh": 0,
        "poor": 2,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }

  
  ,
  {
    "version": "4.10.1",
    "time": "Sep 16, 2024 11:00",
    "crash": 99.987,
    "result": 85,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 73,
        "meh": 23,
        "poor": 4,
        "p75": 1012,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 92,
        "meh": 8,
        "poor": 0,
        "p75": 1561,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 15, //10
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 85,
        "meh": 0,
        "poor": 15,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
  ,
  {
    "version": "4.10.2",
    "time": "Sep 19, 2024 14:40",
    "crash": 99.987,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 73,
        "meh": 22,
        "poor": 5,
        "p75": 1091,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 70,
        "meh": 15,
        "poor": 15,
        "p75": 2400, //2687,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 89,
        "meh": 11,
        "poor": 0,
        "p75": 17,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 71,
        "meh": 0,
        "poor": 29,
        "p75": 0.40,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
,
  {
    "version": "4.10.3",
    "time": "Sep 24, 2024 14:36",
    "crash": 99.987,
    "result": 76,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 54,
        "meh": 40,
        "poor": 6,
        "p75": 1599,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 1059,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 11, //6,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 57,
        "meh": 0,
        "poor": 43,
        "p75": 0.41,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "4.11.0",
    "time": "Sep 24, 2024 15:32",
    "crash": 99.961,
    "result": 86,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 84,
        "meh": 14,
        "poor": 2,
        "p75": 884,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 75,
        "meh": 25,
        "poor": 0,
        "p75": 2258,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 10,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 50,
        "meh": 0,
        "poor": 50,
        "p75": 0.43,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "4.11.1",
    "time": "Sep 26, 2024 11:11",
    "crash": 99.987,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 72,
        "meh": 25,
        "poor": 3,
        "p75": 1014,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 1188,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 21,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 90,
        "meh": 0,
        "poor": 10,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }  
  ,
  {
    "version": "4.11.2",
    "time": "Sep 30, 2024 16:38",
    "crash": 99.987,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 70,
        "meh": 20,
        "poor": 10,
        "p75": 1128,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 79,
        "meh": 14,
        "poor": 7,
        "p75": 1903,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 22,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 86,
        "meh": 0,
        "poor": 14,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
    ,
  {
    "version": "4.11.3",
    "time": "Oct 3, 2024 15:38",
    "crash": 99.983,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 71,
        "meh": 23,
        "poor": 6,
        "p75": 1230,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 81,
        "meh": 19,
        "poor": 0,
        "p75": 1865,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 82,
        "meh": 18,
        "poor": 0,
        "p75": 55,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 81,
        "meh": 0,
        "poor": 19,
        "p75": 0.00,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "4.13.0",
    "time": "Nov 2, 2024 17:03",
    "crash": 99.983,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 70,
        "meh": 20,
        "poor": 9,
        "p75": 987.25,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 88,
        "meh": 4,
        "poor": 8,
        "p75": 1000.12,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 82,
        "meh": 18,
        "poor": 0,
        "p75": 21.65,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 81,
        "meh": 0,
        "poor": 19,
        "p75": 0.0015,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }  
  ,
  {
    "version": "4.13.1",
    "time": "Nov 7, 2024 22:08",
    "crash": 99.983,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 66,
        "meh": 27,
        "poor": 7,
        "p75": 1000.01,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 91,
        "meh": 3,
        "poor": 6,
        "p75": 1000.29,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 26.5,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0022,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }  
  ,
  {
    "version": "4.13.2",
    "time": "Nov 14, 2024 01:36",
    "crash": 99.983,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 66,
        "meh": 27,
        "poor": 7,
        "p75": 1000.01,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 91,
        "meh": 3,
        "poor": 6,
        "p75": 1000.12,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 16.32,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0013,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }  
  ,
  {
    "version": "5.0.33",
    "time": "Nov 14, 2024 17:07",
    "crash": 99.979,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 70,
        "meh": 20,
        "poor": 10,
        "p75": 767.95,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 82,
        "meh": 10,
        "poor": 8,
        "p75": 909.3,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 90,
        "meh": 10,
        "poor": 0,
        "p75": 16,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0012,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }  ,
  {
    "version": "5.0.34",
    "time": "Nov 18, 2024 17:31",
    "crash": 99.65,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 85,
        "meh": 10,
        "poor": 5,
        "p75": 717,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 80,
        "meh": 10,
        "poor": 10,
        "p75": 1714,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 16,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0019,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
  ,
  {
    "version": "5.1.0",
    "time": "Nov 22, 2024 00:07",
    "crash": 99.97,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 88,
        "meh": 11,
        "poor": 1,
        "p75": 1150,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 67,
        "meh": 17,
        "poor": 17,
        "p75": 1770,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 9.8,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0013,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
  ,
  {
    "version": "5.1.1",
    "time": "Nov 27, 2024 13:32", 
    "crash": 99.97,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 43,
        "meh": 51,
        "poor": 6,
        "p75": 816,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 67,
        "meh": 29,
        "poor": 4,
        "p75": 871,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 95,
        "meh": 0,
        "poor": 5,
        "p75": 20.40,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "5.1.2",
    "time": "Nov 27, 2024 14:00", 
    "crash": 99.97,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 38,
        "meh": 60,
        "poor": 2,
        "p75": 1180,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 60,
        "meh": 36,
        "poor": 4,
        "p75": 3004,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 50,
        "meh": 40,
        "poor": 10,
        "p75": 121,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.19,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }
  ,
  {
    "version": "5.1.3",
    "time": "Nov 27, 2024 17:09", 
    "crash": 99.97,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 43,
        "meh": 56,
        "poor": 3,
        "p75": 1180,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 65,
        "meh": 33,
        "poor": 2,
        "p75": 3004,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 66,
        "meh": 30,
        "poor": 4,
        "p75": 106,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.192,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
  ,
  {
    "version": "5.1.4",
    "time": "Nov 28, 2024 13:06", 
    "crash": 99.87,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 80,
        "meh": 20,
        "poor": 0,
        "p75": 484,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 67,
        "meh": 29,
        "poor": 4,
        "p75": 512,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 85,
        "meh": 10,
        "poor": 5,
        "p75": 30,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0007,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
  ,
  {
    "version": "5.1.5",
    "time": "Nov 28, 2024 16:16", 
    "crash": 99.77,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 53,
        "meh": 41,
        "poor": 6,
        "p75": 1470,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 30,
        "meh": 60,
        "poor": 10,
        "p75": 5920,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 91,
        "meh": 6,
        "poor": 3,
        "p75": 40,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0002,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  } 
  ,
  {
    "version": "5.2.0",
    "time": "Nov 28, 2024 17:23", 
    "crash": 99.905,
    "result": 73,
    "all": {
        "val": "WebVitals",
        "good": 44,
        "meh": 39,
        "poor": 17,    
    },
    "metric": [ 
      { 
        "val": "FCP",
        "good": 86,
        "meh": 12,
        "poor": 2,
        "p75": 751, //836
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 68,
        "meh": 28,
        "poor": 4,
        "p75": 1440, //1620
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 70,
        "meh": 30,
        "poor": 0,
        "p75": 44.7, // 56
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 100,
        "meh": 0,
        "poor": 0,
        "p75": 0.0017,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]
  }                                               
];


export const metricsOS = [
  {
    "version": "iOS",
    "time": "Mar 26, 2024 2:00 PM",
    "metric": [ 
      { 
        "val": "FCP",
        "good": 9,
        "meh": 80,
        "poor": 11,
        "p75": 2452,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 0,
        "meh": 0,
        "poor": 0,
        "p75": 0,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 0,
        "meh": 0,
        "poor": 0,
        "p75": 0,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 0,
        "meh": 0,
        "poor": 0,
        "p75": 0,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]    
  },
  {
    "version": "Android",
    "time": "Mar 26, 2024 12:22 PM",
    "metric": [ 
      { 
        "val": "FCP",
        "good": 3,
        "meh": 27,
        "poor": 70,
        "p75": 8455,
        "param": {"good":"<1s","meh":">1s","poor":">3s"}
      },
      { 
        "val": "LCP",
        "good": 19,
        "meh": 25,
        "poor": 56,
        "p75": 8403,
        "param": {"good":"<2.5s","meh":">2.5s","poor":">4s"}
      },
      {
        "val": "FID",
        "good": 86,
        "meh": 6,
        "poor": 8,
        "p75": 48,
        "param": {"good":"<0.1s","meh":">0.1s","poor":">0.3s"}
      },
      { 
        "val":"CLS",
        "good": 0,
        "meh": 0,
        "poor": 0,
        "p75": 0,
        "param": {"good":"<0.01s","meh":">0.01s","poor":">0.025s"}
      }
    ]       
  }  
];