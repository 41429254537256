import { metrics, metricsOS } from './const';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Col, Row, Slider } from 'antd';
import { CheckCircleTwoTone, FireTwoTone, WarningTwoTone } from '@ant-design/icons';
import MpDonut from './MpDonut';
import MpGauge from './MpGauge';
import MpStack from './MpStack';
import MpStack1 from './MpStack1';
import MiniColumn from './Column';
import AvgGrade from './AvgGradeGoogle';
import AvgGradeGoogle from './AvgGradeGoogle';
import AvgGradeApple from './AvgGradeApple';
import MonthGradeGoogle from './MonthGradeGoogle';
import Day28GradeGoogle from './Day28GradeGoogle';
import AvgGradeRustore from './AvgGradeRustore';
import AvgGradeAppGallery from './AvgGradeAppGallery';
import CrashRate from './crashrate';
import Itog from './itog';

let fcp_ = []
let lcp_ = []
let fid_ = []
let cls_ = []
let d = [
     {
      type: '3.5.16',
      value: 5.907,
    },
    {
      type: '3.6.0',
      value: 6.143,
    },
    {
      type: '3.6.1',
      value: 5.839,
    },
];  
let fcp = []
let lcp = []
let fid = []
let cls = []
let all = []

// const data1 = [
//     {
//       label: 'FCP',
//       type: '3.5.16 Mar 26, 2024',
//       value: 5907,
//     },
//     {
//       label: 'FCP',
//       type: '3.6.0 Apr 4, 2024',
//       value: 6143,
//     },
//     {
//       label: 'FCP',
//       type: '3.6.1 Apr 5, 2024',
//       value: 5839,
//     },
//     {
//       label: 'LCP',
//       type: '3.5.16 Mar 26, 2024',
//       value: 7466,
//     },
//     {
//       label: 'LCP',
//       type: '3.6.0 Apr 4, 2024',
//       value: 8036,
//     },
//     {
//       label: 'LCP',
//       type: '3.6.1 Apr 5, 2024',
//       value: 7410,
//     }
//   ];
// const dataFCP = [
//     {
//       label: 'FCP',
//       type: '3.5.16 Mar 26, 2024',
//       value: 5907,
//     },
//     {
//       label: 'FCP',
//       type: '3.6.0 Apr 4, 2024',
//       value: 6143,
//     },
//     {
//       label: 'FCP',
//       type: '3.6.1 Apr 5, 2024',
//       value: 5839,
//     }
//   ];

// const dataLCP = [
//     {
//       label: 'LCP',
//       type: '3.5.16 Mar 26, 2024',
//       value: 7466,
//     },
//     {
//       label: 'LCP',
//       type: '3.6.0 Apr 4, 2024',
//       value: 8036,
//     },
//     {
//       label: 'LCP',
//       type: '3.6.1 Apr 5, 2024',
//       value: 7410,
//     }
//   ];  
//  const data2 = [
//     {
//       label: 'FID',
//       type: '3.5.16 Mar 26, 2024',
//       value: 80,
//     },
//     {
//       label: 'FID',
//       type: '3.6.0 Apr 4, 2024',
//       value: 56,
//     },
//     {
//       label: 'FID',
//       type: '3.6.1 Apr 5, 2024',
//       value: 83,
//     }   
//   ];  

// const data3 = [
//     {
//       label: 'CLS',
//       type: '3.5.16 Mar 26, 2024',
//       value: 0.42,
//     },
//     {
//       label: 'CLS',
//       type: '3.6.0 Apr 4, 2024',
//       value: 0,
//     },
//     {
//       label: 'CLS',
//       type: '3.6.1 Apr 5, 2024',
//       value: 0.83,
//     }    
//   ];  

  for (let key in metrics) {
    //console.log('A', key, metrics[key]);
    // let arr1 = metrics[key].all;

    //   if (arr1) {
    //     all.push({"version":metrics[key].version, "time": metrics[key].time, "good": arr1[k].good, "meh": arr1[k].meh, "poor": arr1[k].poor})
    //   }    
    

    let arr = metrics[key].metric;
    for (let k in arr) {
      if (arr[k].val === "FCP") {
        fcp_.push({"type": metrics[key].version, "value": arr[k].p75 / 1000})
        fcp.push({"version":metrics[key].version, "time": metrics[key].time, "good": arr[k].good, "meh": arr[k].meh, "poor": arr[k].poor, "p": arr[k].p75})
      }
      if (arr[k].val === "LCP") {
        lcp_.push({"type": metrics[key].version, "value": arr[k].p75 / 1000})
        lcp.push({"version":metrics[key].version, "time": metrics[key].time, "good": arr[k].good, "meh": arr[k].meh, "poor": arr[k].poor, "p": arr[k].p75})
      }
      if (arr[k].val === "FID") {
        fid_.push({"type": metrics[key].version, "value": arr[k].p75})
        fid.push({"version":metrics[key].version, "time": metrics[key].time, "good": arr[k].good, "meh": arr[k].meh, "poor": arr[k].poor, "p": arr[k].p75})
      }
      if (arr[k].val === "CLS") {
        cls_.push({"type": metrics[key].version, "value": arr[k].p75 })
        cls.push({"version":metrics[key].version, "time": metrics[key].time, "good": arr[k].good, "meh": arr[k].meh, "poor": arr[k].poor, "p": arr[k].p75})
      }     
    }
  }  
//console.log('fcp', fcp)
//console.log('lcp',lcp)
//console.log('fid', fid)
//console.log('cls', cls)
  // for (let key in metricsOS) {
  //   console.log(metricsOS[key]);
  // } 

const Mp = () => {
const [rerender, setRerender] = useState(); // or any state
const [afterRender, setAfterRender] = useState();// internal state
  function Item(item) {

        console.log('item', item)
        const listItems = item.item.map((value) => (  

              <>
                
                <div style={{width: "500px", display: "flex"}}>
                  <div style={{paddingRight: "20px"}}>{value.val} {value['p75']} ms</div>
                  <div style={{paddingRight: "20px"}}><CheckCircleTwoTone twoToneColor="darkseagreen" /> {value['good']} % ({value['param']['good']})</div>
                  <div style={{paddingRight: "20px"}}><WarningTwoTone twoToneColor="gold" /> {value['meh']} % ({value['param']['meh']})</div>
                  <div style={{paddingRight: "20px"}}><FireTwoTone twoToneColor="orangered" /> {value['poor']} % ({value['param']['poor']})</div>
                </div>
                <div style={{width: "500px", display: "flex", paddingBottom: "20px"}}>
                  <div style={{width: value['good']+ '%', backgroundColor: "darkseagreen", height: "10px", alignContent: "center", textAlign: "center"}}></div>
                  <div style={{width: value['meh']+ '%', backgroundColor: "gold", height: "10px", alignContent: "center", textAlign: "center"}}></div>
                  <div style={{width: value['poor']+ '%', backgroundColor: "orangered", height: "10px", alignContent: "center", textAlign: "center"}}></div>
                </div>
              </>
        ))
      return <div>{listItems}</div>;
  }

  function List() {
      //let arr = props.m;
      const listItems = metrics.map((met) => (
          <div style={{paddingBottom: "40px"}}>
            Версия "МП Курьеры": <b>{met.version}</b> {met.time}
            <Item item={met.metric} />
          </div>
      ));
      return <>{listItems}</>;
  }
  
  function MetricItem(item) {
        ///const {item, name } = props
        
        //console.log('item',  item)
        const listItems = item.item.map((value) => (  

              <div style={{paddingLeft: '30px', paddingBottom: '10px'}}>
                
                <div style={{width: "90%", display: "flex"}}>
                  <div style={{width: '20%',paddingRight: "20px"}}>Версия: <b>{value['version']}</b></div> 
                  <div style={{width: '40%',paddingRight: "20px"}}><b>{value['time']}</b></div>
                  <div style={{width: '20%', paddingRight: "15px"}}><b>{value['p']}</b> ms</div>
                  {/* <div style={{width: '20%', paddingRight: "5px"}}><CheckCircleTwoTone twoToneColor="darkseagreen" /> {value['good']} % </div>
                  <div style={{width: '20%',paddingRight: "5px"}}><WarningTwoTone twoToneColor="gold" /> {value['meh']} % </div>
                  <div style={{width: '20%',paddingRight: "5px"}}><FireTwoTone twoToneColor="orangered" /> {value['poor']} % </div> */}
                </div>
                <div style={{width: "90%", display: "flex"}}>
                  {value['good']>0 
                  ?
                  <div style={{width: value['good']+ '%', backgroundColor: "darkseagreen", height: "10px", alignContent: "center", textAlign: "center", padding: '10px', color: 'white', lineHeight: '3px'}}>
                  {value['good'] + "%"} 
                  </div>
                  : null
                  }
                  {value['meh']>0 
                  ?
                  <div style={{width: value['meh']+ '%', backgroundColor: "gold", height: "10px", alignContent: "center", textAlign: "center", padding: '10px', lineHeight: '3px'}}>
                  {value['meh'] + "%"}
                  </div>
                  : null
                  }
                  {value['poor']>0 
                  ?
                  <div style={{width: value['poor']+ '%', backgroundColor: "orangered", height: "10px", alignContent: "center", textAlign: "center", padding: '10px', color: 'white', lineHeight: '3px'}}>
                   {value['poor'] + "%"} 
                  </div>
                  : null
                  }
                </div>
                {/* <div style={{width: "600px", display: "flex", paddingBottom: "20px"}}>
                  
                  <div style={{width: value['good']+ '%', color: "darkseagreen"}}>{value['good']}%</div>
                  <div style={{width: value['meh']+ '%', color: "gold"}}>{value['meh']}%</div>
                  <div style={{width: value['meh']+ '%', color: "orangered"}}>{value['poor']}%</div>
                </div>                 */}
              </div>
        ))
      return <div>{listItems}</div>;
  }

  function MetricItemAll(item) {
        //const {item, name } = props
        
        console.log('item',  item)
        const listItems = item.item.map((value) => (  

              <div style={{paddingLeft: '30px', paddingBottom: '10px'}}>
                
                <div style={{width: "50%", display: "flex"}}>
                  <div style={{width: '20%',paddingRight: "20px"}}>Версия: <b>{value['version']}</b></div> 
                  <div style={{width: '40%',paddingRight: "20px"}}>Релиз: <b>{value['time']}</b></div>
                </div>
                <div style={{width: "600px", display: "flex"}}>
                  {value['good']>0 
                  ?
                  <div style={{width: value['good']+ '%', backgroundColor: "darkseagreen", height: "10px", alignContent: "center", textAlign: "center", padding: '10px', color: 'white', lineHeight: '3px'}}>
                  {value['good'] + "%"} 
                  </div>
                  : null
                  }
                  {value['meh']>0 
                  ?
                  <div style={{width: value['meh']+ '%', backgroundColor: "gold", height: "10px", alignContent: "center", textAlign: "center", padding: '10px', lineHeight: '3px'}}>
                  {value['meh'] + "%"}
                  </div>
                  : null
                  }
                  {value['poor']>0 
                  ?
                  <div style={{width: value['poor']+ '%', backgroundColor: "orangered", height: "10px", alignContent: "center", textAlign: "center", padding: '10px', color: 'white', lineHeight: '3px'}}>
                   {value['poor'] + "%"} 
                  </div>
                  : null
                  }
                </div>
              </div>
        ))
      return <div>{listItems}</div>;
  }

  function ListOS() {
      //let arr = props.m;
      const listItems = metricsOS.map((met) => (
          <div style={{paddingBottom: "40px"}}>
            Версия "МП Курьеры": <b>{met.version}</b> {met.time}
            <Item item={met.metric} />
          </div>
      ));
      return <>{listItems}</>;
  }

  async function click() {
    console.log('click');
    let response = await fetch(
      //'https://delivery-api-test.vkusvill.ru/v1/tasks/get_return_reasons',
       'https://courier-profile-test.vkusvill.ru/v1/rating/courier/1799929484',
    {
      method: 'GET', 
      // Тело запроса в JSON-формате
      //mode: 'no-cors',
      //"mode":"cors",
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'x-vkusvill-token': 'RHwyKgNlGDprL88wFQaVKngTDEQtUgDx',
        'x-vkusvill-device': 'seagull_team',
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJjb3VyaWVySWQiOjE3OTk5Mjk0ODQsInNlcmlhbE51bSI6IjFiNWJmYzg4LTkzMzItNDM2Mi04Nzk3LWFlOWQzMmEwMGU5NSIsImV4cGlyZWQiOjE3MTYzODI3MzcsImFwcF9tZXRhZGF0YSI6eyJhdXRob3JpemF0aW9uIjp7InJvbGVzIjpbXX0sImlzX2RldmVsb3BlciI6ZmFsc2UsImlzX3N1cGVyX2FkbWluIjpmYWxzZX19.BiEDvfGX1HinFvvyy2QqB87CLIZNmvwbY5CR19S_j0uITMdA-qd05omWpkO6eILfARHEmAnIEPjqZoq0I94ADBh0vB0-4JT3PEu7nvmeVeoxVKdW9iL_lN9a4qX5WK3eq4dcwvb1dPBQkrMEND0WWsNywsJNYFNxKeAhuiZ5N3Y'
      },
  }) 
  


    if (response.ok) { // если HTTP-статус в диапазоне 200-299
      // получаем тело ответа (см. про этот метод ниже)
      let json = await response.json();
      console.log(json);
    } else {
      console.log("Ошибка HTTP: " + response.status);
    }
  }

  function hashgo() {
  
    let hash = window.location.hash;
    document.location.hash = hash;
    console.log("Hi " + hash);

  }
//setTimeout(hashgo, 2000);
// useLayoutEffect(() => {
//    hashgo();
// }, []);


  return (
    <div style={{height: 'calc(100vh - 280px)'}}>
      <div  style={{height: 'calc(120vh - 280px)', overflow: 'auto'}}>
      {/* <button onClick={click}>click</button> */}
      {/* <List />  */}
      {/* <ListOS/> */}
 
      {/* <MpDonut name="5907 ms" /> */}
      {/* <MpGauge /> */}
      <h3>Метрики производительности WEB Vitals "МП Курьеры" (Frontend)</h3>

    {/* <Row>
      <Col span={24} style={{padding: '20px'}}> 
        <h3>Индекс производительности (стандарты google) <span style={{color: "orangered"}}>0 .. 49 - плохо</span> <span style={{color: "gold"}}>50 .. 89 - хорошо</span> <span style={{color: "darkseagreen"}}>90 .. 100 - отлично</span> </h3>

        <table className='tables'>
              <tr>
                <th scope="col">Версия 3.5.16</th>
                <th scope="col">Версия 3.6.0</th>
                <th scope="col">Версия 3.6.1</th>
                <th scope="col">Версия 4.0.0</th>
                <th scope="col">Версия 4.0.1</th>
                <th scope="col">Версия 4.0.3</th>
                <th scope="col">Версия 4.1.3</th>
                <th scope="col">Версия 4.1.4</th>
                <th scope="col">Версия 4.1.5</th>
                <th scope="col">Версия 4.2.1</th>
                <th scope="col">Версия 4.3.5</th>
                <th scope="col">Версия 4.4.0</th>
                <th scope="col">Версия 4.5.0</th>
                <th scope="col">Версия 4.5.2</th>
                <th scope="col">Версия 4.5.3</th>
                <th scope="col">Версия 4.6.0</th>
              </tr>
              <tr>
                <td style={{textAlign: "center", color: "orangered"}}><h1>30</h1></td>
                <td style={{textAlign: "center", color: "orangered"}}><h1>34</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈49</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈53</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈65</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈63</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈64</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈62</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈58</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈60</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈68</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈70</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈63</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈60</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈57</h1></td>
                <td style={{textAlign: "center", color: "gold"}}><h1>≈56</h1></td>
              </tr> 
        </table> 
        <div>
           
        </div>        
      </Col>
    </Row>  */}
    
    <br /><br />
    
    <Row>
      <Col span={12}
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 12,
            //offset: 2,
          }}
      > 
      {/* <h3>Относительные показатели (%)</h3> */}
        <div style={{ paddingBottom: '20px'}}><b>FCP Загрузка</b> <CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 1 сек <WarningTwoTone twoToneColor="gold" /> - больше 1 сек <FireTwoTone twoToneColor="orangered" /> - больше 3 сек</div>
        <MetricItem item={fcp}/>
        <br /><br />
        <div style={{ paddingBottom: '20px'}}><b>LCP Отображение</b> <CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 2.5 сек <WarningTwoTone twoToneColor="gold" /> - больше 2.5 сек <FireTwoTone twoToneColor="orangered" /> - больше 4 сек</div>
        <MetricItem item={lcp}/>
        <br /><br />
      </Col>
      <Col span={12}
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 12,
            //offset: 2,
          }}
      
      >   
        <div style={{ paddingBottom: '20px'}}><b>FID Отзывчивость</b> <CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 0.1 сек <WarningTwoTone twoToneColor="gold" /> - больше 0.1 сек <FireTwoTone twoToneColor="orangered" /> - больше 0.3 сек</div>
        <MetricItem item={fid}/>
        <br /><br />
        <div style={{ paddingBottom: '20px'}}><b>CLS Визуальная стабильность</b> <CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 0.01 сек <WarningTwoTone twoToneColor="gold" /> - больше 0.01 сек <FireTwoTone twoToneColor="orangered" /> - больше 0.025 сек</div>
        <MetricItem item={cls}/>
        <br /><br />
      </Col>
    </Row> 

  {/* <Row>
      <Col span={24}> 

        <div style={{ paddingBottom: '20px'}}><b>Web Vitals</b> <CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 1 сек <WarningTwoTone twoToneColor="gold" /> - больше 1 сек <FireTwoTone twoToneColor="orangered" /> - больше 3 сек</div>
        <MetricItemAll item={fcp}/>
      </Col>
  </Row> */}


    <br /><br />
    <h3>Метрики по 75 процентилю</h3>

    <Row>
        <Col span={6} 
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 6,
            //offset: 2,
          }}
          style={{padding: '20px'}}> 
          <h3>FCP Загрузка</h3>
                    <ul>
            <li>Скорость подключения к сети</li>
            <li>Медленный ответа сервера</li>
            <li>Оптимизированный CSS & JS </li>
            <li>Кеширование</li>  
          </ul>
          <MiniColumn data={fcp_} />
          <p>измеряет время с момента начала загрузки страницы до момента отображения какой-либо части содержимого страницы на экране</p>

        </Col>
        <Col span={6} 
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 6,
            //offset: 2,
          }}
        
        style={{padding: '20px'}}>
          <h3>LCP Отображение</h3>
                    <ul>
            <li>Медленный ответа сервера</li>
            <li>Блокировки при рендере CSS & JS </li>
            <li>Медленная загрузка ресурсов</li>
            <li>Рендеринг на клиенте</li>
          </ul>
          <MiniColumn data={lcp_} />
          <p>измеряет время с момента начала загрузки страницы до момента отображения на экране самого большого текстового блока или элемента изображения.</p>

        </Col>

        <Col span={6} 
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 6,
            //offset: 2,
          }}
        
        style={{padding: '20px'}}> 
          <h3>FID Отзывчивость</h3>
          <ul>
            <li>Медленный ответа сервера</li>
            <li>Длительное исполнение JS</li>
            <li>Размер JS бандла для выполнения</li>
            <li>Рендер блокировки JS</li>
          </ul>
          <MiniColumn data={fid_} />
          <p>измеряет время от момента, когда пользователь впервые взаимодействует со страницей, до момента, когда браузер действительно может начать перехват и обработку событий в ответ на это взаимодействие.</p>
        </Col>
        <Col span={6} 
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 6,
            //offset: 2,
          }}
        style={{padding: '20px'}}>
          <h3>CLS Визуальная стабильность</h3>
            <ul>
            <li>Картинки без указания размера</li>
            <li>IFame без указания размера</li>
            <li>Динамический отображаемый контент</li>
            <li>Загружаемые шрифты</li>
          </ul>
          <MiniColumn data={cls_} />
          <p>измеряет совокупную оценку всех неожиданных сдвигов макета, которые происходят между началом загрузки страницы и моментом, когда состояние ее жизненного цикла меняется на скрытое.</p>

        </Col>
      </Row> 
      
      <br /><br/><br /><br/><br /><br/><br /><br/>
     
      <a name="metric"></a>
      <Row >
        <Col span={12}
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 12,
            //offset: 2,
          }}
          style={{paddingBottom: "30px", paddingLeft: "20px", paddingRight: "20px"}}
        >
          <CrashRate/>
          <p>Метрика сеансов без сбоев — это процент сеансов, которые произошли в течение выбранного периода времени и не закончились сбоем . Сеансы без сбоев указывают на общую надежность приложения и повышают доверие пользователей.</p>
        </Col>
        <Col span={12}
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 12,
            //offset: 2,
          }}
          style={{paddingBottom: "30px", paddingLeft: "20px", paddingRight: "20px"}}
        >
          <Itog />
          <p>Оценка производительности представляет собой средневзвешенное значение показателей FCP, LCP, FID и CLS. Более взвешенные показатели оказывают большее влияние на общий показатель производительности:
          <ul>
          <li>Скорость загрузки первого контента (FCP) влияние 15%</li>
          <li>Скорость загрузки основного контента (LCP) влияние 20%</li>
          <li>Время загрузки для взаимодействия (FID) влияние 30%</li>
          <li>Совокупный сдвиг верстки (CLS) влияние 30%</li>
          </ul>
          Ссылки: <a href="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring?utm_source=lighthouse&utm_medium=lr&hl=ru" target="_blank">Оценка производительности</a>, <a href="https://googlechrome.github.io/lighthouse/scorecalc/" target="_blank">Lighthouse Scoring Calculator</a>
          </p>
        </Col>
      </Row>

      <h3>Crash Android</h3>
      <Row>
        <Col span={24}>
          <img src="g.png" width="100%"/>
        </Col>

      </Row>
      <h3>Crash Apple</h3>
      <Row>
        <Col span={24}>
          <img src="a.png" width="100%"/>
        </Col>

      </Row>
{      
/*
      <h3>Page Load Over Time Android</h3>
      <Row>
        <Col span={24}>
          <img src="pg.png" width="100%"/>
        </Col>

      </Row>
      <h3>Page Load Over Time iOS</h3>
      <Row>
        <Col span={24}>
          <img src="pa.png" width="100%"/>
        </Col>

      </Row>
*/
}
      {/* <h3>Крэши по  курьерам</h3>
      <a href="https://docs.google.com/spreadsheets/d/1wYoS6oekyt5N_fRZ-Yz92N9lOVSj-GVXuFu5f3HOOxM/edit?usp=sharing" target='_blank'>Таблица exel</a> 
      */}

      {/* <br /><br/><br /><br/><br /><br/><br /><br/>
      <h3>Crash Android</h3>
      <Row>
        <Col span={12}>
          <img src="crashG1.png" width="100%"/>
        </Col>
        <Col span={12}>  
          <img src="crashG2v.png" width="100%"/>
        </Col>
      </Row>
      <h3>Crash Apple</h3>
      <Row>
        <Col span={12}>
          <img src="crashA1.png" width="100%"/>
        </Col>
        <Col span={12}>
          <img src="crashA2v.png" width="100%"/>
        </Col>
      </Row> */}
      


      {/* <h3>Sentry Инценденты</h3>
      <Row>
        <Col span={24}>
          <img src="sentry.png" width="100%"/>
        </Col>
      </Row> */}
      <br/><br/>
      <br/><br/>
      <h2>Оценки в Аппсторах</h2>
      <Row>
        <Col span={12} 
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 12,
            //offset: 2,
          }}
        style={{padding: '20px', height: '200px'}}>   
          <AvgGradeGoogle /> 
          <br /><br/>
          <MonthGradeGoogle />
          <br /><br />
          <Day28GradeGoogle />   
        </Col>
        <Col span={12} 
          xs={{
            span: 24,
            //offset: 1,
          }}
          lg={{
            span: 12,
            //offset: 2,
          }}
        style={{padding: '20px', height: '200px'}}>   
          <AvgGradeApple />
          <br /><br/>
          <AvgGradeRustore/>
          <br/><br/>
          <AvgGradeAppGallery />
        </Col>
        
      </Row> 

      {/* <br /><br/><br /><br/><br /><br/><br /><br/>
      <br /><br/><br /><br/><br /><br/><br /><br/>
      <br /><br/><br /><br/><br /><br/><br /><br/> */}


      <Row>
        <Col span={24} style={{padding: '20px', height: '50px'}}>  
        f           
        </Col>        
      </Row> 

       {/* <br /><br/><br /><br/><br /><br/><br /><br/>  
       <br /><br/><br /><br/><br /><br/><br /><br/>  

      <Row> */}
        {/* <Col span={24} style={{padding: '20px'}}>   
        <table className='tables'>
          <tr>
            <th scope="col">Метрика</th>
            <th scope="col">Описание метрики</th>
            <th scope="col">Критерии оценки</th>
            <th scope="col">Критерия качества</th>
            <th scope="col">Влияние %</th>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}><b>FCP</b></td>
            <td>(First Contentful Paint) — время с момента открытия до момента отображения содержимого</td>
            <td><CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 1 сек <WarningTwoTone twoToneColor="gold" /> - больше 1 сек <FireTwoTone twoToneColor="orangered" /> - больше 3 сек</td>
            <td style={{textAlign: "center"}}>Скорость загрузки</td>
            <td style={{textAlign: "center"}}>15%</td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}><b>LCP</b></td>
            <td>(Largest Contentful Paint) — время отображения самого большого визуального элемента</td>
            <td><CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 2.5 сек <WarningTwoTone twoToneColor="gold" /> - больше 2.5 сек <FireTwoTone twoToneColor="orangered" /> - больше 4 сек</td>
            <td style={{textAlign: "center"}}>Скорость загрузки</td>
            <td style={{textAlign: "center"}}>25%</td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}><b>FID</b></td>
            <td>(First Input Delay) — время отклика, первого взаимодействия пользователи с приложением</td>
            <td><CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 0.1 сек <WarningTwoTone twoToneColor="gold" /> - больше 0.1 сек <FireTwoTone twoToneColor="orangered" /> - больше 0.3 сек</td>
            <td style={{textAlign: "center"}}>Отзывчивость</td>
            <td style={{textAlign: "center"}}>30%</td>        
          </tr>
          <tr>
            <td style={{textAlign: "center"}}><b>CLS</b></td>
            <td>(Cumulative Layout Shift) - измеряет степень стабильности контента (смещение контента)</td>
            <td><CheckCircleTwoTone twoToneColor="darkseagreen" /> - меньше 0.01 сек <WarningTwoTone twoToneColor="gold" /> - больше 0.01 сек <FireTwoTone twoToneColor="orangered" /> - больше 0.025 сек</td>
            <td style={{textAlign: "center"}}>Визуальная стабильность</td>
            <td style={{textAlign: "center"}}>30%</td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}><b>P75</b></td>
            <td>(75 процентиль) - время метрики у 75% пользователей меньше либо равен этому значению</td>
            <td></td>
            <td style={{textAlign: "center"}}></td>
          </tr>
          <tr>
            <td style={{textAlign: "center"}}><b>Crash Free Session Rate</b></td>
            <td>процент сеансов завершившихся без сбоев. Сеансы без сбоев указывают на общую надежность приложения и повышают доверие пользователей.</td>
            <td></td>
            <td style={{textAlign: "center"}}></td>
          </tr>

          
        </table>       
       </Col> */}
    {/* </Row>       */}
    </div>
    </div> 
  )
};

export default Mp;