import React, { useState, useEffect } from 'react';
import { Layout, theme } from 'antd';
import { Checkbox, Divider, Alert, Space } from 'antd';
import Papa from "papaparse";
import Analitic from './Analitic';
import moment from 'moment';
import { Button, Form, Input } from 'antd';
import { etalon } from './const';
import Mp from './mp';
const { Header, Content } = Layout;

const items = new Array(3).fill(null).map((_, index) => ({
  key: String(index + 1),
  label: `nav ${index + 1}`,
}));


const CheckboxGroup = Checkbox.Group;
const plainOptions = ['МП Покупателя', 'МП Курьер', 'УЗ', 'CRM Доставка'];
const defaultCheckedList = ['МП Курьер'];

const App = () => {
  var commonConfig = { delimiter: ";" };
  const [auth, setAuth] = useState(false);
  const [authInfo, setAuthInfo] = useState('');
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  const onChange = (list) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  const [data, setData] = useState([]);
  const [alarm, setAlarm] = useState([]);
  const [graph, setGraph] = useState([]);
  const [itog, setItog] = useState([]);

  const [dataResult,setdataResult] = useState({});
  const [dataCouriers, setdataCouriers] = useState([]);
  const [dataDeliveryApi, setdataDeliveryApi] = useState([]);
  const [dataCourierRewardsCalculation, setdataCourierRewardsCalculation] = useState([]);
  const [dataSql, setdataSql] = useState([]);

 
const onFinish = (values) => {
  //console.log('Success:', values.password);

  if (values.password === 's937') {
    setAuth(true);
    sessionStorage.setItem('ok', 1);
  } else {
    setAuthInfo('Ошибка!')
  }
};
const onFinishFailed = (errorInfo) => {
  //console.log('Failed:', errorInfo);
};

const Login = () => (
  <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >

    
    <Form.Item
      label="Введите код"
      name="password"
      rules={[
        {
          required: true,
          message: 'Please input your code!',
        },
      ]}
    >
      
      <Input.Password />
    </Form.Item>


    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Войти
      </Button>
    </Form.Item>
  </Form>
);

  const [CSVData, setCSVData] = useState();
  //const [parsedString, setParsedString] = useState();
  //const [unparsedString, setUnparsedString] = useState();
  // const etalon = {
  // "01-01-2024":  {time: "01-01-2024", count: 0},
  // "02-01-2024":  {time: "02-01-2024", count: 0},
  // "03-01-2024":  {time: "03-01-2024", count: 0},
  // "04-01-2024":  {time: "04-01-2024", count: 0},
  // "05-01-2024":  {time: "05-01-2024", count: 0},
  // "06-01-2024":  {time: "06-01-2024", count: 0},
  // "07-01-2024":  {time: "07-01-2024", count: 0},
  // "08-01-2024":  {time: "08-01-2024", count: 0},
  // "09-01-2024":  {time: "09-01-2024", count: 0},
  // "10-01-2024":  {time: "10-01-2024", count: 0},
  // "11-01-2024":  {time: "11-01-2024", count: 0},
  // "12-01-2024":  {time: "12-01-2024", count: 0},
  // "13-01-2024":  {time: "13-01-2024", count: 0},
  // "14-01-2024":  {time: "14-01-2024", count: 0},
  // "15-01-2024":  {time: "15-01-2024", count: 0},
  // "16-01-2024":  {time: "16-01-2024", count: 0},
  // "17-01-2024":  {time: "17-01-2024", count: 0},
  // "18-01-2024":  {time: "18-01-2024", count: 0},
  // "19-01-2024":  {time: "19-01-2024", count: 0},
  // "20-01-2024":  {time: "20-01-2024", count: 0},
  // "21-01-2024":  {time: "21-01-2024", count: 0},
  // "22-01-2024":  {time: "22-01-2024", count: 0},
  // "23-01-2024":  {time: "23-01-2024", count: 0},
  // "24-01-2024":  {time: "24-01-2024", count: 0},
  // "25-01-2024":  {time: "25-01-2024", count: 0},
  // "26-01-2024":  {time: "26-01-2024", count: 0},
  // "27-01-2024":  {time: "27-01-2024", count: 0},
  // "28-01-2024":  {time: "28-01-2024", count: 0},
  // "29-01-2024":  {time: "29-01-2024", count: 0},
  // "30-01-2024":  {time: "30-01-2024", count: 0},
  // "31-01-2024":  {time: "31-01-2024", count: 0},
  // "01-02-2024":  {time: "01-02-2024", count: 0},
  // "02-02-2024":  {time: "02-02-2024", count: 0},
  // "03-02-2024":  {time: "03-02-2024", count: 0},
  // "04-02-2024":  {time: "04-02-2024", count: 0},
  // "05-02-2024":  {time: "05-02-2024", count: 0},
  // "06-02-2024":  {time: "06-02-2024", count: 0},
  // "07-02-2024":  {time: "07-02-2024", count: 0},
  // "08-02-2024":  {time: "08-02-2024", count: 0},
  // "09-02-2024":  {time: "09-02-2024", count: 0},
  // "10-02-2024":  {time: "10-02-2024", count: 0},
  // "11-02-2024":  {time: "11-02-2024", count: 0},
  // "12-02-2024":  {time: "12-02-2024", count: 0},
  // "13-02-2024":  {time: "13-02-2024", count: 0},
  // "14-02-2024":  {time: "14-02-2024", count: 0},
  // "15-02-2024":  {time: "15-02-2024", count: 0},
  // "16-02-2024":  {time: "16-02-2024", count: 0},
  // "17-02-2024":  {time: "17-02-2024", count: 0},
  // "18-02-2024":  {time: "18-02-2024", count: 0},
  // "19-02-2024":  {time: "19-02-2024", count: 0},
  // "20-02-2024":  {time: "20-02-2024", count: 0},
  // "21-02-2024":  {time: "21-02-2024", count: 0},
  // "22-02-2024":  {time: "22-02-2024", count: 0},
  // "23-02-2024":  {time: "23-02-2024", count: 0},
  // "24-02-2024":  {time: "24-02-2024", count: 0},
  // "25-02-2024":  {time: "25-02-2024", count: 0},
  // "26-02-2024":  {time: "26-02-2024", count: 0},
  // "27-02-2024":  {time: "27-02-2024", count: 0},
  // "28-02-2024":  {time: "28-02-2024", count: 0},
  // "29-02-2024":  {time: "29-02-2024", count: 0},
  // };




  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // datamy.csv
  var RES = {};
  //function parseCSVDataResult() {
    Papa.parse(
       "./data/datamy.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {
          let x = {};
          
          result.data.forEach(element => {
            
            x[element[0]] = {
              //"desc": element[1],
              //"descmer": element[2],
              "stack": element[3],
              "mer": element[4],
              "command": element[5]
            }
          });

          //console.log(x);
          // x.sort((a, b) => a.id - b.id);
          RES =  x;
          //setdataResult(x);
        }  
      }
    )
  //}

  // couriers.csv
  function parseCSVDataCouriers() {
    Papa.parse(
       "./data/couriers.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {
          let x = [];
          let y = Object.assign({}, etalon);
          result.data.forEach(element => {
            let d1 = element[1].substr(8, 2);
            let m1 = element[1].substr(5, 2) - 1;
            let y1 = element[1].substr(0, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
            let t = moment(d).format('DD-MM-YYYY');
            //console.log(y[t].time, y[t].count)
            if (y[t].time === t) {
              y[t].count = y[t].count + 1;
            }
            // if (Object.hasOwn(y, d)) {
            //   y[d].count = y[d].count + 1;
            // } else {
            //   y[d] = {"time": t, "count": 1}
            // }
          })

          for (let key in y) {
            let d1 = key.substr(0, 2);
            let m1 = key.substr(3, 2) - 1;
            let y1 = key.substr(6, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
              x.push({
                "id": d, //key,
                "date": y[key].time,
                "value": y[key].count,
                "type": "Front",
              })
          }

          x.sort((a, b) => a.id - b.id);
          setdataCouriers(x);
          //console.log('sort', x);
        }  
      }
    )
  }

  // delivery-api.csv
  function parseCSVDataDeliveryApi() {
    Papa.parse(
       "./data/delivery-api.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {          
          let x = [];
          let y = Object.assign({}, etalon);
          result.data.forEach(element => {
              let d1 = element[1].substr(8, 2);
              let m1 = element[1].substr(5, 2) - 1;
              let y1 = element[1].substr(0, 4);
              let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
              let t = moment(d).format('DD-MM-YYYY');

              if (y[t].time === t) {
                y[t].count = y[t].count + 1;
              }
              // if (Object.hasOwn(y, d)) {
              //   y[d].count = y[d].count + 1;
              // } else {
              //   y[d] = {"time": t, "count": 1}
              // }
            })

          for (let key in y) {
            let d1 = key.substr(0, 2);
            let m1 = key.substr(3, 2) - 1;
            let y1 = key.substr(6, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
              x.push({
                "id": d, //key,
                "date": y[key].time,
                "value": y[key].count,
                "type": "delivery-api",
              })
          }

            x.sort((a, b) => a.id - b.id);
            setdataDeliveryApi(x);
            //console.log('sort', x);          
          
        }  
      }
    )
  }  

  // courier-rewards-calculation.csv
  function parseCSVDataCourierRewardsCalculation() {
    Papa.parse(
       "./data/courier-rewards-calculation.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {          
          let x = [];
          let y = Object.assign({}, etalon);
          result.data.forEach(element => {
            let d1 = element[1].substr(8, 2);
            let m1 = element[1].substr(5, 2) - 1;
            let y1 = element[1].substr(0, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
            let t = moment(d).format('DD-MM-YYYY');
            if (y[t].time === t) {
              y[t].count = y[t].count + 1;
            }
            // if (Object.hasOwn(y, d)) {
            //   y[d].count = y[d].count + 1;
            // } else {
            //   y[d] = {"time": t, "count": 1}
            // }
          })

          for (let key in y) {
            let d1 = key.substr(0, 2);
            let m1 = key.substr(3, 2) - 1;
            let y1 = key.substr(6, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
              x.push({
                "id": d, //key,
                "date": y[key].time,
                "value": y[key].count,
                "type": "courier-rewards-calculation",
              })
          }

          x.sort((a, b) => a.id - b.id);
          setdataCourierRewardsCalculation(x);
          //console.log('sort', x);         
          
        }  
      }
    )
  } 

  // courier-rewards-calculation.csv
  function parseCSVDataSql() {
    Papa.parse(
       "./data/sql.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {          
          let x = [];
          let y = Object.assign({}, etalon);
          result.data.forEach(element => {
            let d1 = element[1].substr(8, 2);
            let m1 = element[1].substr(5, 2) - 1;
            let y1 = element[1].substr(0, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
            let t = moment(d).format('DD-MM-YYYY');
            if (y[t].time === t) {
              y[t].count = y[t].count + 1;
            }
            // if (Object.hasOwn(y, d)) {
            //   y[d].count = y[d].count + 1;
            // } else {
            //   y[d] = {"time": t, "count": 1}
            // }
          })

          for (let key in y) {
            let d1 = key.substr(0, 2);
            let m1 = key.substr(3, 2) - 1;
            let y1 = key.substr(6, 4);
            let d = new Date(y1, m1, d1, 0, 0, 0).getTime();
              x.push({
                "id": d, //key,
                "date": y[key].time,
                "value": y[key].count,
                "type": "MSSQL-Databases",
              })
          }

          x.sort((a, b) => a.id - b.id);
          setdataSql(x);
          //console.log('sort', x);         
          
        }  
      }
    )
  } 

  // Parse remote CSV file
  function parseCSVData() {
    Papa.parse(
       "./data/data.csv",
      {
        ...commonConfig,
        header: false,
        download: true,
        complete: (result) => {
          
          let d_light = [];
          let d_alarm = [];
          let d_graph = [];
          let d_itog = [];

          let ia = 1;
          let il = 1;
          let ig = 1;
          result.data.forEach(element => {
            //console.log('***', element[0], element);
            var d1 = element[1].substr(0, 2);
            var m1 = element[1].substr(3, 2);
            var y1 = element[1].substr(6, 4);
            var date0 = new Date(y1, m1, d1, 0, 0, 0);
            
            var at1 = element[2].split(":");
            
            var at2 = element[3].split(":");
            //var t1 = parseFloat(at1[0] * 1 + (at1[1]* 1 * 0.01)).toFixed(2) * 100; 
            //var t2 = parseFloat(at2[0] * 1 + (at2[1]* 1 * 0.01)).toFixed(2) * 100; 

            var t1 = parseInt((at1[0] * 1 + (at1[1]* 1 * 0.01)) * 100); 
            var t2 = parseInt((at2[0] * 1 + (at2[1]* 1 * 0.01)) * 100); 
//console.log(element[0],element[1],  t1, t2)
            var checkElement = false;
            let arrlink = [];
            if (element[9]) {
                let a = element[9].split('http');
                a.map((l) => {
                  if (l) arrlink.push("http" + l)
                })
            }

            let colRow = 0;
            //console.log(checkedList);
            checkedList.forEach(el => {
              //['МП Покупателя', 'Сайт', 'МП Курьер', 'Сборка', 'Кассы', 'МП Склада', 'CRM Доставка'];

              if (el === 'МП Покупателя') {
                if (element[12] * 1 >0) { checkElement = true; colRow = colRow + element[12] * 1; }
              }

              if (el === 'МП Курьер') {
                if (element[14] * 1 >0) { checkElement = true;  colRow = colRow + element[14] * 1;}
              }
              if (el === 'CRM Доставка') {
                if (element[19] * 1 >0) { checkElement = true;  colRow = colRow + element[19] * 1; }
              }
              if (el === 'УЗ') {
                if (element[45] * 1 >0) { checkElement = true;  colRow = colRow + element[45] * 1; }
              }
            })

            // 96 проблема!;
            // 97 сделано;
            // 98 стэк;
            // 99 фикс;
            // 100 ???;
            //console.log('app', element[98]);
            let arrStack = [];
            let fix = '';
            let desc = '';
            let res = '';
            let com = '';
            // if (element[98]) {
            //   arrStack = element[98].split(',');

            // }
            //console.log('app', element)
            //console.log(RES,RES[element[0]]);
            let aaa = RES[element[0]];
            if (aaa) {
                arrStack = aaa.stack.split(',');
                fix = aaa.mer;
                desc = aaa.desc;
                res = aaa.descmer;
                com = aaa.command;
            }


            //if (element[98]) {
            if (element[0]<9999) {
              if (colRow>0) {              
                // d_itog.push({
                //   "key":element[0],
                //   "dat": date0,
                //   "date": '' + d1 + '-' + m1 + '-' + y1,
                //   "minutes": element[6] * 1,
                //   "stack": arrStack, 
                //   "fix": element[99],
                //   "info": element[74],
                //   "desc": (element[96]) ? element[96] : element[4],
                //   "res": (element[97]) ? element[97] : element[5],
                //   "tip": element[11],
                //   "mpp": element[12] * 1,
                //   "site": element[13] * 1,
                //   "kur": element[14] * 1,
                //   "uz": element[45] * 1,
                // })

                d_itog.push({
                  "key":element[0],
                  "dat": date0,
                  "date": '' + d1 + '-' + m1 + '-' + y1,
                  "minutes": element[6] * 1,
                  "stack": arrStack, 
                  "fix": fix,
                  "info": element[74],
                  "desc": desc ? desc : element[4],
                  "res": res ? res : element[5],
                  "tip": element[11],
                  "mpp": element[12] * 1,
                  "site": element[13] * 1,
                  "kur": element[14] * 1,
                  "uz": element[45] * 1,
                  "k+s": element[92] * 1,
                  "k": element[93] * 1,
                  "com": com,
                })
              }
            }

            if (element[0]<9999) {
              if (colRow>0) {
              //if (element[14] * 1 >0) {
                d_graph.push({
                  "num": ig,
                  "key": element[0] * 1,
                  "date": '' + d1 + '-' + m1 + '-' + y1, 
                  "dat": date0,
                  "date_begin": element[2],
                  "date_end": element[3],
                  "time": element[2] + " - " + element[3],
                  "minutes": element[6] * 1,
                  "description": element[4],
                  "report":element[5],
                  "type1":element[10],
                  "type2":element[11],
                  "mpp": element[12] * 1,
                  "site": element[13] * 1,
                  "kur": element[14] * 1,
                  "uz": element[45] * 1,
                  "rep":  (element[78].substr(0, 8)) === 'https://' ? '': element[78],
                  "t1": t1,
                  "t2": t2,
                  "link": arrlink,
                  "txt":element[74],
                  "k+s": element[92] * 1,
                  "k": element[93] * 1,
                  "com": com,
                });
                ig++;
              }
            }

            if (element[0]<9999) {
              if (colRow>0) {
              //if (element[14]>0) {
                d_light.push({
                  "num": il,
                  "key": element[0] * 1,
                  "date": '' + d1 + '-' + m1 + '-' + y1,
                  "dat": date0, 
                  "date_begin": element[2],
                  "date_end": element[3],
                  "time": element[2] + " - " + element[3],
                  "minutes": element[6] * 1,
                  "description": element[4],
                  "report":element[5],
                  "type1":element[10],
                  "type2":element[11],
                  "mpp": element[12] * 1,
                  "site": element[13] * 1,
                  "kur": element[14] * 1,
                  "uz": element[45] * 1,
                  "rep":  (element[78].substr(0, 8)) === 'https://' ? '': element[78],
                  "t1": t1,
                  "t2": t2,
                  "link": arrlink,
                  "txt":element[74],
                });
                il++;
              }
            } else {
              if (colRow>0) {
              //if (element[14]>0) {
                d_alarm.push({
                  "num": ia,
                  "key": element[0] * 1,
                  "date": '' + d1 + '-' + m1 + '-' + y1, 
                  "dat": date0,
                  "date_begin": element[2],
                  "date_end": element[3],
                  "time": element[2] + " - " + element[3],
                  "minutes": element[6] * 1,
                  "description": element[4],
                  "report":element[5],
                  "type1":element[10],
                  "type2":element[11],
                  "mpp": element[12] * 1,
                  "site": element[13] * 1,
                  "kur": element[14] * 1,
                  "uz": element[45] * 1,
                  "rep":  (element[78].substr(0, 8)) === 'https://' ? '': element[78],
                  "t1": t1,
                  "t2": t2,
                  "link": arrlink,
                  "txt":element[74],
                });
                ia++;
              }
            }
          });
          
          //console.log('до', d_light);
          //d_light.sort((a, b) => a.dat - b.dat); 
          //d_alarm.sort((a, b) => a.dat - b.dat); 
          d_light.sort((a, b) => a.key - b.key); 
          d_alarm.sort((a, b) => a.key - b.dat); 
          d_graph.sort((a, b) => a.key - b.key);
          //console.log('после', d_light, d_graph, d_itog);

          setData(d_light);
          setAlarm(d_alarm);
          setGraph(d_graph);
          setItog(d_itog);
          //console.log('после', d_itog);
        }
      }
    );
  }

  useEffect(() => {
    //parseCSVDataResult();
    parseCSVData();
    parseCSVDataCouriers();
    parseCSVDataDeliveryApi();
    parseCSVDataCourierRewardsCalculation(); 
    parseCSVDataSql();

  }, [checkedList]);  //[]); //



  // let s = sessionStorage.getItem('ok');
  // if (s === 1) {
  //   setAuth(true);
  // } 


//  if (auth) {
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="demo-logo" style={{color: 'white', fontSize: '18px'}}>Стабильность МП Курьер (09.12.2024)</div>
      </Header>
      <Content style={{padding: '14px'}}>
        <div
          style={{
            padding: 24,
            height: 'calc(100vh - 22px)',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div style={{display: "none"}}>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              Все
            </Checkbox>

            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
            <Divider />  
          </div> 
              
          {/* <Analitic data={data} alarm={alarm} graph={graph} ch={checkedList} itog={itog}
            dataCouriers={dataCouriers}
            dataDeliveryApi={dataDeliveryApi}
            dataCourierRewardsCalculation={dataCourierRewardsCalculation}
            dataSql={dataSql}
          /> */}
          <Mp /> 
        </div>
      </Content>
    </Layout>
  );
  // } else {
  //   return (
  //   <div style={{padding: '200px'}}>
        
    
  //     {
  //     (authInfo)
  //     ?
  //     <div style={{padding: '0px 200px 50px 100px'}} >
  //       <Alert
  //         message="Error"
  //         description={authInfo}
  //         type="error"
  //       />
  //     </div>
  //     : ''
  //     }
      
  //     <Login />
  //   </div>
  //   );
  // }
};
export default App;
